<template>
     <div id="thankyou" class="text-center mt-5">
            <h1 class="h2">Order Placed</h1>
            <p>
                Thank you for your order, we appreciate your business.<br>
                A confirmation/receipt email will be sent to {{ thankyou_email }}.
            </p>
            <p v-if="show_new_location">
                Our new pickup location is located at <b>1149 Commerce Way</b>.<br>
                Please pull up and back in to the front doors. Can't wait to see you!
            </p>

            <p class="text-center">
                <router-link to="/" class="btn btn-secondary">Back to Catalog</router-link>
            </p>
        </div>
</template>

<script>
    export default {
        props: ['thankyou_email', 'show_new_location'],

        metaInfo () {
            return {
                title: 'Thank You',
                meta: [
                    { charset: 'utf-8' },
                    { vmid: 'robots', name: 'robots', content: 'noindex' }

                ]
            }
        },

        mounted () {
            if (!this.thankyou_email) {
                this.$router.replace('/');
            }
        }
    }
</script>

<style>

</style>